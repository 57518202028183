<template>
  <Dialog v-model="dialog" title="Really delete version?">
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <default-button :on="on" color="error" icon="fa-trash-alt"
        >Delete version</default-button
      >
    </template>
    <v-card-text>
      You are about to delete version {{ version.number }} of this NPM package.
      This action cannot be undone. Are you sure you want to proceed?
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="error" :loading="loading" @click="deleteVersion"
        >Delete version</v-btn
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import { mutations } from "@/gql";
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";
const { deleteNpmVersion: mutation } = mutations;

export default {
  props: ["version"],
  components: { DefaultButton, Dialog },
  data: () => ({
    error: false,
    errorMessage: null,
    loading: false,
    dialog: false,
  }),
  methods: {
    deleteVersion() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            pk: this.version.id,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.$emit("delete");
            this.dialog = false;
          }
        });
    },
  },
};
</script>
