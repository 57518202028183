<template>
  <Dialog
    btn-text="Edit package permissions"
    btn-icon="fa-lock"
    btn-color="primary"
    v-model="dialog"
    :title="`Edit permissions for ${pkg.name}`"
  >
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-card-text>
      <v-form v-model="valid">
        <v-checkbox
          v-model="form.public"
          label="Publicly accessible?"
          hint="If enabled, anybody with the relevant URL will be able to install this package"
          persistent-hint
          class="pb-2"
        />
        <v-radio-group v-model="form.mode">
          <template v-slot:label>
            <div class="subheading font-weight-bold">Permissions mode</div>
          </template>
          <v-radio value="account_level">
            <template v-slot:label>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Account level </v-list-item-title>
                  <v-list-item-subtitle>
                    With account-level permissions, access to this package is
                    controlled by the default system roles
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-radio>
          <v-radio value="package_level">
            <template v-slot:label>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Package level </v-list-item-title>
                  <v-list-item-subtitle>
                    With this option, Packagr will create roles with the
                    following package-specific permissions:
                    <ul class="py-2">
                      <li>
                        <strong>{{ pkg.name }} - CREATE</strong>
                      </li>
                      <li>
                        <strong>{{ pkg.name }} - READ</strong>
                      </li>
                      <li>
                        <strong>{{ pkg.name }} - DESTROY</strong>
                      </li>
                    </ul>
                    Users must have a role with the above permissions assigned
                    in order to interact with this package.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button
        icon="fa-plus"
        color="success"
        :disabled="!valid"
        @click="editPermissions"
        :loading="loading"
      >
        Save changes
      </default-button>
    </v-card-actions>
  </Dialog>
</template>
<script>
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";
import { mutations } from "@/gql";

const { editNpmPackagePermissions: mutation } = mutations;

export default {
  components: { DefaultButton, Dialog },
  props: ["pkg"],
  data: () => ({
    valid: null,
    dialog: false,
    loading: false,
    form: {
      public: false,
      mode: "account_level",
    },
    error: false,
    errorMessage: null,
  }),
  mounted() {
    this.form.public = this.pkg.public;
    this.form.mode = this.pkg.mode;
  },
  methods: {
    editPermissions() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            pk: this.pkg.id,
            ...this.form,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.dialog = false;
          }
        });
    },
  },
};
</script>
