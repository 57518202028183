<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    item-key="name"
    class="elevation-0"
  >
    <template v-slot:item.number="{ item }">
      {{ item.metadata._id }}
    </template>
    <template v-slot:item.fileSize="{ item }">
      {{ item.fileSize | fileSize }}
    </template>
    <template v-slot:item.updated="{ item }">
      {{ item.updated | niceDate }}
    </template>
    <template v-slot:item.install="{ item }">
      <copy-button :copy-string="getInstallationInstructions(item)"
        >Copy installation instructions</copy-button
      >
    </template>
    <template v-slot:item.download="{ item }">
      <default-button icon="fa-download" :href="item.fileUrl"
        >Download</default-button
      >
    </template>
    <template v-slot:item.metadata="{ item }">
      <metadata-dialog :metadata="item.metadata" />
    </template>
    <template v-slot:item.actions="{ item }">
      <delete-npm-version-dialog @delete="$emit('refresh')" :version="item" />
    </template>
  </v-data-table>
</template>

<script>
import { fileSize, niceDate } from "@/filters";
import CopyButton from "@/components/widgets/CopyButton";
import MetadataDialog from "@/components/dialogs/MetadataDialog";
import DeleteNpmVersionDialog from "@/components/dialogs/DeleteNpmVersionDialog";
import DefaultButton from "@/components/widgets/DefaultButton";

export default {
  components: {
    DefaultButton,
    DeleteNpmVersionDialog,
    MetadataDialog,
    CopyButton,
  },
  props: ["items"],
  filters: { fileSize, niceDate },
  methods: {
    getInstallationInstructions(item) {
      return `npm install ${item.metadata._id}`;
    },
  },
  data: () => ({
    headers: [
      {
        text: "Version",
        value: "number",
      },
      {
        text: "File size",
        value: "fileSize",
      },
      {
        text: "Date published",
        value: "updated",
      },
      {
        text: "Installation instructions",
        value: "install",
      },
      {
        text: "Download",
        value: "download",
      },
      {
        text: "Metadata",
        value: "metadata",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
};
</script>
