<template>
  <span>
    <v-snackbar v-model="snackbar" color="success"
      >Copied to clipboard!</v-snackbar
    >
    <default-button
      :icon="icon"
      v-clipboard="copyString"
      v-clipboard:success="onSuccess"
      color="grey darken-2"
    >
      <slot />
    </default-button>
  </span>
</template>

<script>
import DefaultButton from "@/components/widgets/DefaultButton";
export default {
  props: {
    copyString: { type: String, required: true },
    icon: { type: String, default: "fa-copy" },
  },
  components: { DefaultButton },
  data: () => ({
    snackbar: false,
  }),
  methods: {
    onSuccess() {
      this.snackbar = true;
    },
  },
};
</script>
