var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"item-key":"name"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metadata._id)+" ")]}},{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fileSize")(item.fileSize))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceDate")(item.updated))+" ")]}},{key:"item.install",fn:function(ref){
var item = ref.item;
return [_c('copy-button',{attrs:{"copy-string":_vm.getInstallationInstructions(item)}},[_vm._v("Copy installation instructions")])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('default-button',{attrs:{"icon":"fa-download","href":item.fileUrl}},[_vm._v("Download")])]}},{key:"item.metadata",fn:function(ref){
var item = ref.item;
return [_c('metadata-dialog',{attrs:{"metadata":item.metadata}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-npm-version-dialog',{attrs:{"version":item},on:{"delete":function($event){return _vm.$emit('refresh')}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }