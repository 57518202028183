<template>
  <v-row class="ma-4" v-if="npmPackage">
    <page-header>NPM Package: {{ npmPackage.name }}</page-header>
    <v-col cols="12">
      <v-card tile>
        <table-header title="Versions">
          <edit-npm-permissions :pkg="npmPackage" />
          <default-button
            @click="$apollo.queries.npmPackage.refetch()"
            icon="fa-sync"
            data-test="refresh-btn"
          >
            Refresh
          </default-button>
        </table-header>
        <v-card-text>
          <npm-version-table
            @refresh="$apollo.queries.npmPackage.refetch()"
            :items="npmPackage.versions"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import NpmVersionTable from "@/components/tables/NpmVersionTable";
import { queries } from "@/gql";
import EditNpmPermissions from "@/components/dialogs/EditNpmPermissions";
import DefaultButton from "@/components/widgets/DefaultButton";

const { npmPackage: query } = queries;

export default {
  components: {
    DefaultButton,
    EditNpmPermissions,
    NpmVersionTable,
    TableHeader,
    PageHeader,
  },
  apollo: {
    npmPackage: {
      query,
      variables() {
        return { pk: this.$route.params.id };
      },
      error() {
        this.$router.push("/npm");
        this.$router.go();
      },
    },
  },
};
</script>
